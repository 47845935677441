import {
  ApolloError,
  ApolloQueryResult,
  gql,
  useApolloClient,
} from "@apollo/client";
import {
  DataConnectionId,
  DataSourceTableId,
  ExploreUserJoin,
} from "@hex/common";
import React, { useCallback, useRef, useState } from "react";

import { shallowCompare } from "../../util/shallowCompare.js";

import {
  TestJoinConfigDocument,
  TestJoinConfigQuery,
  TestJoinConfigQueryVariables,
  TestUniqueKeyConfigDocument,
  TestUniqueKeyConfigQuery,
  TestUniqueKeyConfigQueryVariables,
} from "./exploreConfigureJoinValidation.generated.js";

gql`
  query TestJoinConfig(
    $dataConnectionId: DataConnectionId!
    $baseDataSourceTableId: DataSourceTableId!
    $baseColumnName: String!
    $targetDataSourceTableId: DataSourceTableId!
    $targetColumnName: String!
  ) {
    testJoin(
      dataConnectionId: $dataConnectionId
      baseDataSourceTableId: $baseDataSourceTableId
      baseColumnName: $baseColumnName
      targetDataSourceTableId: $targetDataSourceTableId
      targetColumnName: $targetColumnName
    ) {
      dbError
      hadValidationTimeout
      hasFanout
      hasNoMatches
    }
  }
`;

gql`
  query TestUniqueKeyConfig(
    $dataConnectionId: DataConnectionId!
    $dataSourceTableId: DataSourceTableId!
    $columnName: String!
  ) {
    testUniqueKey(
      dataConnectionId: $dataConnectionId
      dataSourceTableId: $dataSourceTableId
      columnName: $columnName
    ) {
      dbError
      keyNotUnique
    }
  }
`;

const getJoinError = ({
  joinTestResult,
}: {
  joinTestResult: TestJoinConfigQuery["testJoin"] | undefined;
}): React.ReactNode | undefined => {
  if (joinTestResult == null) return undefined;

  if (joinTestResult.hadValidationTimeout)
    return "Timed out while validating. Your join might not be valid and could lead to slow performance.";

  if (joinTestResult.dbError != null)
    return "An error happened in the data connection while testing your configuration.";

  if (joinTestResult.hasNoMatches)
    return "The chosen columns have no matching values. Try matching other columns or choosing a new table to join.";

  return undefined;
};

interface JoinValidationParams {
  dataConnectionId?: DataConnectionId;
  baseDataSourceTableId?: DataSourceTableId;
  targetDataSourceTableId?: DataSourceTableId;
  baseColumnName?: string;
  targetColumnName?: string;
}

interface UseJoinValidationResult {
  clearJoinValidation: () => void;
  startJoinValidation: (params: JoinValidationParams) => void;

  isValidated: boolean;
  isLoading: boolean;
  validationError: React.ReactNode | undefined;
  joinTestResult: TestJoinConfigQuery["testJoin"] | undefined;
  relationshipType: ExploreUserJoin["relationshipType"] | undefined;
}

export const useJoinValidation = (): UseJoinValidationResult => {
  const client = useApolloClient();

  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState<
    React.ReactNode | undefined
  >(undefined);
  const [joinTestResult, setJoinTestResult] =
    useState<TestJoinConfigQuery["testJoin"]>();
  const abortController = useRef<AbortController | null>(null);
  const prevVariables = useRef<object | null>(null);

  const startJoinValidation = useCallback(
    async function ({
      baseColumnName,
      baseDataSourceTableId,
      dataConnectionId,
      targetColumnName,
      targetDataSourceTableId,
    }: JoinValidationParams) {
      if (
        dataConnectionId == null ||
        baseDataSourceTableId == null ||
        baseColumnName == null ||
        targetDataSourceTableId == null ||
        targetColumnName == null
      ) {
        setIsLoading(false);
        setIsValidated(false);
        setValidationError("Missing matching column configuration.");
        setJoinTestResult(undefined);
        return;
      }

      const variables = {
        dataConnectionId,
        baseDataSourceTableId,
        baseColumnName,
        targetDataSourceTableId,
        targetColumnName,
      };

      // If the input is exactly the same, no need to rerun validation
      if (shallowCompare(variables, prevVariables.current)) {
        return;
      }
      prevVariables.current = variables;

      setIsLoading(true);
      setIsValidated(false);
      setValidationError(undefined);
      setJoinTestResult(undefined);

      if (abortController.current) {
        abortController.current.abort();
      }
      abortController.current = new AbortController();

      let resp: ApolloQueryResult<TestJoinConfigQuery>;
      try {
        resp = await client.query<
          TestJoinConfigQuery,
          TestJoinConfigQueryVariables
        >({
          query: TestJoinConfigDocument,
          context: {
            fetchOptions: {
              signal: abortController.current.signal,
            },
          },
          variables,
        });
      } catch (err) {
        setIsLoading(false);

        const rootErr = err instanceof ApolloError ? err.networkError : err;
        if (rootErr instanceof DOMException && rootErr.name === "AbortError") {
          // We only cancel right now when kicking off a new request,
          // so no need to do anything since the new request is handling everything
          return;
        }

        setValidationError("Something went wrong while testing the join.");
        return;
      }

      setIsLoading(false);
      const maybeError = getJoinError({
        joinTestResult: resp.data.testJoin,
      });
      setValidationError(maybeError);
      setJoinTestResult(resp.data.testJoin);
      if (maybeError == null) {
        setIsValidated(true);
      }
    },
    [client],
  );

  const clearJoinValidation = useCallback(() => {
    setIsValidated(false);
    setIsLoading(false);
    setJoinTestResult(undefined);
    abortController.current?.abort();
  }, []);

  return {
    isValidated,
    isLoading,
    validationError,
    clearJoinValidation,
    startJoinValidation,
    joinTestResult,
    relationshipType:
      joinTestResult == null
        ? undefined
        : // If we have a timeout, assume the most general relationship type
          // so that we end up generating SQL that works for all cases
          joinTestResult.hasFanout || joinTestResult.hadValidationTimeout
          ? "to-many"
          : "to-one",
  };
};

const getUniqueKeyError = (
  uniqueKeyTestResult: TestUniqueKeyConfigQuery["testUniqueKey"] | undefined,
  columnName: string,
): string | undefined => {
  if (uniqueKeyTestResult == null) return undefined;

  if (uniqueKeyTestResult.dbError != null)
    return "An error happened in the data connection while testing your configuration.";
  if (uniqueKeyTestResult.keyNotUnique)
    return `Unique key column "${columnName}" has duplicate values, which can lead to incorrect results.`;

  return undefined;
};

interface UniqueKeyValidationParams {
  dataConnectionId?: DataConnectionId;
  dataSourceTableId?: DataSourceTableId;
  columnName?: string;
}

export const useUniqueKeyValidation = () => {
  const client = useApolloClient();

  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState<string | undefined>(
    undefined,
  );
  const abortController = useRef<AbortController | null>(null);
  const prevVariables = useRef<object | null>(null);

  const startUniqueKeyValidation = useCallback(
    async function ({
      columnName,
      dataConnectionId,
      dataSourceTableId,
    }: UniqueKeyValidationParams) {
      if (
        dataConnectionId == null ||
        dataSourceTableId == null ||
        columnName == null
      ) {
        setIsLoading(false);
        setIsValidated(false);
        setValidationError("No unique key is specified");
        return;
      }

      const variables = {
        dataConnectionId,
        dataSourceTableId,
        columnName,
      };

      // If the input is exactly the same, no need to rerun validation
      if (shallowCompare(variables, prevVariables.current)) {
        return;
      }
      prevVariables.current = variables;

      setIsLoading(true);
      setIsValidated(false);
      setValidationError(undefined);
      if (abortController.current) {
        abortController.current.abort();
      }
      abortController.current = new AbortController();

      let resp: ApolloQueryResult<TestUniqueKeyConfigQuery>;
      try {
        resp = await client.query<
          TestUniqueKeyConfigQuery,
          TestUniqueKeyConfigQueryVariables
        >({
          query: TestUniqueKeyConfigDocument,
          context: {
            fetchOptions: {
              signal: abortController.current.signal,
            },
          },
          variables,
        });
      } catch (err) {
        setIsLoading(false);

        const rootErr = err instanceof ApolloError ? err.networkError : err;
        if (rootErr instanceof DOMException && rootErr.name === "AbortError") {
          // We only cancel right now when kicking off a new request,
          // so no need to do anything since the new request is handling everything
          return;
        }

        setValidationError(
          "Something went wrong while validating the unqiue key.",
        );
        return;
      }

      setIsLoading(false);
      const maybeError = getUniqueKeyError(resp.data.testUniqueKey, columnName);
      setValidationError(maybeError);
      if (maybeError == null) {
        setIsValidated(true);
      }
    },
    [client],
  );

  const clearUniqueKeyValidation = useCallback(() => {
    setIsValidated(false);
    setIsLoading(false);
    abortController.current?.abort();
  }, []);

  return {
    isValidated,
    isLoading,
    validationError,
    clearUniqueKeyValidation,
    startUniqueKeyValidation,
  };
};
